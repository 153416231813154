
import { Component, Prop, Vue } from 'vue-facing-decorator'
import { viewModel } from '@/lib/AppService'
import { getLogger } from '@/lib/log'
import {PaymentInit, PaymentItem, PaymentCurrencyAmount} from '@/lib/types'

const logger = getLogger(process.env.VUE_APP_ENABLE_DEBUG_LOGGING)
const log = logger.write
const debugLog = logger.debug

@Component
export default class ApplePay extends Vue {
    @Prop validationErrors: string[] = []
    vm = viewModel

    async mounted() {
        if (!window.PaymentRequest) {
        debugLog('** PaymentRequest Not Supported by Browser...')
        return;
        }

        const existingScript = document.querySelector('#applePayScript')
        if(existingScript == null || existingScript == undefined)
        {
            const script = document.createElement('script')
            script.id = "applePayScript"
            script.src = `https://applepay.cdn-apple.com/jsapi/1.latest/apple-pay-sdk.js`            
            const div = document.querySelector('#app')
            div?.appendChild(script)
        }

         // Define PaymentMethodData
         const paymentMethodData = [{
            "supportedMethods": "apple-pay",
            "data": {
                "version": 3,
                "merchantIdentifier": "merchant.com.assurant.enterprisepaymentgateway.sandbox",
                "merchantCapabilities": [],
                "supportedNetworks": [
                    "amex",
                    "discover",
                    "masterCard",
                    "visa"
                ],
                "countryCode": "US"
            }
        }];
        
        
        const paymentAmount:PaymentCurrencyAmount = {
            currency: this.vm.transactionIntent!.transactionIntent.transactionAmount.currencyCode,
            value: this.vm.transactionIntent!.transactionIntent.transactionAmount.value.toString()}

        console.log("Intent Amount");
        console.log(this.vm.transactionIntent!.transactionIntent.transactionAmount.value.toString());

        const paymentTotal:PaymentItem = {
            label: this.vm.transactionIntent!.transactionIntent.transactionDescription,
            amount: paymentAmount}

        const paymentInit: PaymentInit={
            id : this.vm.transactionIntent!.transactionIntent.id,
            total: paymentTotal}

        // Create PaymentRequest
        const request = new PaymentRequest(paymentMethodData, paymentInit);
        console.log("Payment Request Created");

        this.vm.isApplePaySupported = await request.canMakePayment();
        console.log(this.vm.isApplePaySupported);
    }

    async initiatePayment()
    {
         try {

        // Define PaymentMethodData
        const paymentMethodData = [{
            "supportedMethods": "apple-pay",
            "data": {
                "version": 3,
                "merchantIdentifier": "merchant.com.assurant.enterprisepaymentgateway.sandbox",
                "merchantCapabilities": [],
                "supportedNetworks": [
                    "amex",
                    "discover",
                    "masterCard",
                    "visa"
                ],
                "countryCode": "US"
            }
        }];
        

        const paymentAmount:PaymentCurrencyAmount = {
            currency: this.vm.transactionIntent!.transactionIntent.transactionAmount.currencyCode,
            value: this.vm.transactionIntent!.transactionIntent.transactionAmount.value.toString()
            }

        const paymentTotal:PaymentItem = {
            label: this.vm.transactionIntent!.transactionIntent.transactionDescription,
            amount: paymentAmount
        }

        const paymentInit: PaymentInit={
            id : this.vm.transactionIntent!.transactionIntent.id,
            total: paymentTotal
        }

        // Create PaymentRequest
        const request = new PaymentRequest(paymentMethodData, paymentInit);
        
        request.addEventListener("onmerchantvalidation", this.handleMerchantValidation);       
        
        const response = await request.show();
        const status = "success";
        debugLog(response);
        await response.complete(status);
    } catch (e) {
        // Handle errors
        console.error(e);
    }
    }

    private async handleMerchantValidation(event: any)
    {
        const merchantSessionPromise = await this.vm.validateAppleMerchantSession(event.url);
        
        event.complete(merchantSessionPromise);
    }

    get isPaymentRequestSupported()
    {
        return this.vm.isApplePaySupported;
    }
}
